import Spinner from "../../../UI/Spinner";

const SummaryCard = ({ title, count, isLoading }) => {
    return (
        <div className="card p-3 text-nowrap col-2 align-items-center mr-3 mt-3">
            <div className="d-flex flex-column align-items-center justify-content-center">
                <h3>{title}</h3>
                {isLoading ? <Spinner /> : <h4>{count} </h4>}
            </div>
        </div>
    );
}

export default SummaryCard;