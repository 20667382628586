import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const playerSlice = createApi({
    reducerPath: "playerapi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/players`,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getPlayerDetails: builder.query({
            query: (playerId) => `/${playerId}`,
        }),
        getPlayers: builder.query({
            query: ({ pageNumber, pageSize, searchQuery }) => "?pageNumber=" + pageNumber + "&pageSize=" + pageSize + "&searchQuery=" + searchQuery,
        }),
        createReportRecord: builder.mutation({
            query: (playerId) => ({
                url: `/report-record/${playerId}`,
                method: "POST"
            }),
        }),
        createScoutingReports: builder.mutation({
            query: (playerId) => ({
                url: `/scouting-auto-generation/${playerId}`,
                method: "POST"
            }),
        }),
        getPlayerLookups: builder.query({
            query: (playerId) => `/${playerId}/lookups`,
        }),
    })
});

export const {
    useGetPlayerDetailsQuery,
    useLazyGetPlayersQuery,
    useCreateReportRecordMutation,
    useCreateScoutingReportsMutation,
    useGetPlayerLookupsQuery
} = playerSlice;
