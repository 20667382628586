import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const aoVideosSlice = createApi({
  reducerPath: "videoapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/videos/ao`,
    mode: "cors",
    prepareHeaders: (headers) => {
      const token = getAuthToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getVideos: builder.query({
      query: () => `/`,
    }),
    addVideo: builder.mutation({
      query: ({ url, title, isAtp, id }) => ({
        url: `/`,
        method: "POST",
        contentType: "application/json",
        body: { url, title, is_atp: isAtp, id },
      }),
    }),
  }),
});

export const { useGetVideosQuery, useAddVideoMutation } = aoVideosSlice;