import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";
import {transformMatchPoint} from "../editor/Utils/pointUtils";

export const snapshotsSlice = createApi({
    reducerPath: "snapshotsapi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["CvMatches"],
    endpoints: (builder) => ({
        deleteCvSnapshot: builder.mutation({
            query: ({ videoProcessId, snapshotId }) => ({
                url: `/videos/${videoProcessId}/snapshots/${snapshotId}`,
                method: "DELETE",
            }),
        }),
        getMatchSnapshots: builder.query({
            query: (videoProcessId) => `/videos/${videoProcessId}/snapshots`,
            providesTags: (result, error, videoProcessId) => {
                return ["CvMatches", { type: "CvMatches", videoProcessId }];
            },
        }),
        getCvMatch: builder.query({
            query: ({ videoProcessId, snapshotId }) => `/videos/${videoProcessId}/snapshots/${snapshotId}`,
            providesTags: (result, error, args) => {
                return ["CvMatches", { type: "CvMatches", videoProcessId: args.videoProcessId, snapshot: args.snapshot }];
            },
            extraOptions: { maxRetries: 3 },
            transformResponse: (response) => {
                response.points = response.points.map((p) => transformMatchPoint(p, response));
                return response;
            }
        })
    }),
});

export const {
    useDeleteCvSnapshotMutation,
    useGetMatchSnapshotsQuery,
    useGetCvMatchQuery
} = snapshotsSlice;
