import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectIsAdmin, selectUser, selectIsRecordingTeam } from "../auth/authSlice";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import UpdateLinkingDataConfirmationModal from "./UpdateLinkingDataConfirmationModal";
import Spinner from "../../UI/Spinner";
import { useGetAllReadyVideosForLinkingQuery, useUpdateMetadataMutation, useLazyGetReadyVideoForLinkingByIdQuery } from "../api/collectedVideosSlice";
import {
    setVideos
    , filterVideosByTitle
    , selectFilteredVideos
    , selectSelectedVideo
    , setSelectedVideo
    , updateVideoMetadata
    , removeVideo
} from "./VideosLinkningCodersSlice";
import { useGetAllPlayersQuery, useGetEventInstancesQuery } from "../api/completeMatchesSlice";
import Select from "react-select";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useParams, useSearchParams } from "react-router-dom";
import { formatUtcDate } from "../../utils/app-utils";

export default function VideosLinkingCodersPage() {
    const category = useParams().category;
    const [searchParams, setSearchParams] = useSearchParams();
    const videoProcessId = searchParams.get('videoProcessId');

    const label = category === "ttv"
        ? "Tennis TV"
        : category === "wtv"
            ? "WTA TV"
            : category === "ctv"
                ? "Challenger TV"
                : category === "recording-team"
                    ? "Recording Team"
                    : category === "wtt"
                        ? "ITF"
                        : category === "tc"
                            ? "Tennis Channel"
                            : category === "espn"
                                ? "ESPN"
                                : category === "dropbox"
                                    ? "Dropbox"
                                    : "IMG";

    const dispatch = useDispatch();
    const filteredVideos = useSelector(selectFilteredVideos);
    const selectedVideo = useSelector(selectSelectedVideo);
    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const isRecordingTeam = useSelector(selectIsRecordingTeam);
    const [playerOptions, setPlayerOptions] = useState([]);
    const [winnerOptions, setWinnerOptions] = useState([]);
    const [resultTxt, setResultTxt] = useState('');

    const { data: players, isLoading: playersLoading, isSuccess: playersSuccess, error: playersError, status: playersStatus } = useGetAllPlayersQuery();
    const { data: events, isLoading: eventsLoading, isSuccess: eventsSuccess, error: eventsError, status: eventsStatus } = useGetEventInstancesQuery();
    const { data: videos, isLoading, isFetching, error, isSuccess, status } = useGetAllReadyVideosForLinkingQuery({ category, isLinked: false }, { skip: category === undefined || category === null });
    const count = filteredVideos ? filteredVideos.length : 0;
    const [tournamentOptions, setTournamentOptions] = useState([]);
    const [updateMetadata, { isLoading: isUpdating, isSuccess: isUpdateSuccess, error: updateError, status: updateStatus }] = useUpdateMetadataMutation();
    const [getVideoProcessById] = useLazyGetReadyVideoForLinkingByIdQuery();

    const matchRoundOptions = [
        { value: 0, label: '1st Round' },
        { value: 1, label: '2nd Round' },
        { value: 2, label: '3rd Round' },
        { value: 3, label: '4th Round' },
        { value: 7, label: 'Bronze' },
        { value: 6, label: 'Final Round' },
        { value: 100, label: 'N/A' },
        { value: 8, label: 'Pre-qualifying' },
        { value: 11, label: 'Qualifying' },
        { value: 9, label: 'Qualifying-1' },
        { value: 10, label: 'Qualifying-2' },
        { value: 4, label: 'Quarterfinals Round' },
        { value: 12, label: 'Robin' },
        { value: 13, label: 'Rubber 1' },
        { value: 14, label: 'Rubber 2' },
        { value: 15, label: 'Rubber 3' },
        { value: 16, label: 'Rubber 4' },
        { value: 17, label: 'Rubber 5' },
        { value: 5, label: 'Semifinals Round' },
    ];

    useEffect(() => {
        if (isSuccess) {
            dispatch(setVideos(videos));
        }
    }, [videos]);

    useEffect(() => {
        if (videoProcessId) {
            getVideoProcessById(videoProcessId).then((response) => {
                dispatch(setSelectedVideo(response.data));
            });
        } else {
            dispatch(setSelectedVideo(null));
        }
    }, [videoProcessId]);

    useEffect(() => {
        if (playersSuccess) {
            setPlayerOptions(players.map((player) => ({
                value: player.playerId,
                label: `${player.name} - ${player.category == 2 ? 'WTA' : player.category == 4 ? 'IMG' : 'ATP'}`,
            })));
        }
    }, [playersSuccess, playersError]);

    useEffect(() => {
        if (eventsSuccess) {
            setTournamentOptions(events.map((event) => ({
                value: event.eventInstanceId,
                label: `${event.name} - ${event.category} - ${formatUtcDate(event.startDate)}`,
            })));
        }
    }, [eventsSuccess, eventsError]);


    useEffect(() => {
        if (selectedVideo) {
            if (selectedVideo?.linked_metadata?.player1Id && selectedVideo?.linked_metadata?.player2Id && playerOptions.length > 0) {
                // get winner options from players
                let winners = playerOptions.filter((p) => p.value === selectedVideo?.linked_metadata?.player1Id || p.value === selectedVideo?.linked_metadata?.player2Id);
                setWinnerOptions(winners);
            } else {
                setWinnerOptions([]);
            }
            setResultTxt(selectedVideo.linked_metadata.result);
        }
    }, [selectedVideo, playerOptions]);

    const handleSelectPlayerChange1 = (selectedOption) => {
        const player = players.find((p) => p.playerId === selectedOption.value);
        dispatch(updateVideoMetadata({
            ...selectedVideo.linked_metadata, player1Id: player.playerId
        }));
    };


    const handleSelectPlayerChange2 = (selectedOption) => {
        const player = players.find((p) => p.playerId === selectedOption.value);
        dispatch(updateVideoMetadata({
            ...selectedVideo.linked_metadata, player2Id: player.playerId
        }));
    };


    const handleWinnerChange = (selectedOption) => {
        const option = winnerOptions.find((p) => p.value === selectedOption.value);
        dispatch(updateVideoMetadata({
            ...selectedVideo.linked_metadata, winnerId: option.value
        }));
    };


    const selectedPlayerOption1 = playerOptions.find(
        (player) => selectedVideo?.linked_metadata?.player1Id === player.value
    );

    const selectedPlayerOption2 = playerOptions.find(
        (player) => selectedVideo?.linked_metadata?.player2Id === player.value
    );


    const handleSelectTournamentChange = (selectedOption) => {
        const event = tournamentOptions.find((e) => e.value === selectedOption.value);
        dispatch(updateVideoMetadata({
            ...selectedVideo.linked_metadata, eventInstanceId: event.value
        }));
    };

    const selectedTournamentOption = tournamentOptions.find(
        (event) => selectedVideo?.linked_metadata?.eventInstanceId === event.value
    );


    const selectedWinnerOption = winnerOptions.find(
        (player) => selectedVideo?.linked_metadata?.winnerId === player.value
    );

    const handleRoundChange = (selectedOption) => {
        const round = matchRoundOptions.find((r) => r.value === selectedOption.value);
        dispatch(updateVideoMetadata(
            { ...selectedVideo.linked_metadata, roundId: round.value }
        ));
    };

    const selectedRoundOption = matchRoundOptions.find(
        (round) => selectedVideo?.linked_metadata.roundId === round.value
    );

    if (!user) return <Spinner />;
    else if (!isAdmin && !isRecordingTeam) return <NotAuthorizedPage />;
    if (isLoading || isFetching) return <Spinner />;
    if (error) return <div>
        {status} {error.status} {JSON.stringify(error.error)}
    </div>;

    return (
        <div className="row">
            <UpdateLinkingDataConfirmationModal title={selectedVideo?.title} onConfirmUpdate={() => {
                updateMetadata({
                    videoId: selectedVideo.id,
                    player1Id: selectedVideo.linked_metadata.player1Id,
                    player2Id: selectedVideo.linked_metadata.player2Id,
                    eventInstanceId: selectedVideo.linked_metadata.eventInstanceId,
                    winnerId: selectedVideo.linked_metadata.winnerId,
                    result: selectedVideo.linked_metadata.result,
                    resultType: selectedVideo.linked_metadata.resultType,
                    roundId: selectedVideo.linked_metadata.roundId,
                    matchType: selectedVideo.linked_metadata.matchType
                }).then((response) => {
                    dispatch(removeVideo({ id: selectedVideo.id }));
                    dispatch(setSelectedVideo(null));
                }).catch((error) => {
                    console.log(error);
                });
            }} />
            <div className="col-4 mb-3">
                <div className="card mt-1 min-vh-75 max-vh-75">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-12">
                                <h5>{label} Videos ({count})</h5>
                            </div>
                            <div className="col-12 mt-1">
                                <input type="text" className="form-control" placeholder="Search by name" onChange={(x) => {
                                    dispatch(filterVideosByTitle(x.target.value));
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0 scrollbar perfect-scrollbar">
                        <div className="list-group">
                            {filteredVideos.map(video => {
                                let classes = "list-group-item list-group-item-action";
                                if (video.title === selectedVideo?.title) {
                                    classes += " bg-500 text-white";
                                }
                                return (
                                    <a
                                        style={{ cursor: "pointer" }}
                                        key={video.id}
                                        className={classes}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            setSearchParams({ videoProcessId: video.id });
                                        }}
                                    >
                                        <strong>{video.title}</strong>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-8 mb-3">
                {selectedVideo !== null && (
                    <div>
                        <div className="card mt-1">
                            <div className="card-header">
                                <h5>{selectedVideo.title}</h5>
                            </div>
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-3 m-2">
                                        <strong>Id</strong>
                                    </div>
                                    <div className="col-7 m-2">
                                        <strong>{selectedVideo.id}</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <CopyToClipboard text={selectedVideo.browserURL}>
                                    <button className="btn btn-primary mr-1 mb-1 fs--1" type="button">Browser URL</button>
                                </CopyToClipboard>
                            </div>
                        </div>
                        <div className="card mt-1">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-3 m-2">
                                        <strong>{selectedVideo.metadata.p1}</strong>
                                    </div>
                                    <div className="col-7 m-2">
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={playerOptions}
                                            onChange={handleSelectPlayerChange1}
                                            value={selectedPlayerOption1 ?? ""}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3 m-2">
                                        <strong>{selectedVideo.metadata.p2}</strong>
                                    </div>
                                    <div className="col-7 m-2">
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={playerOptions}
                                            onChange={handleSelectPlayerChange2}
                                            value={selectedPlayerOption2 ?? ""}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3 m-2">
                                        <strong>{selectedVideo.metadata.tournament}</strong>
                                    </div>
                                    <div className="col-7 m-2">
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={tournamentOptions}
                                            onChange={handleSelectTournamentChange}
                                            value={selectedTournamentOption ?? ""}
                                        />
                                    </div>
                                </div>
                                {/*  Get winner */}
                                <div className="row">
                                    <div className="col-3 m-2">
                                        <strong>Winner</strong>
                                    </div>
                                    <div className="col-7 m-2">
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={winnerOptions}
                                            onChange={handleWinnerChange}
                                            value={selectedWinnerOption ?? ""}
                                        />
                                    </div>
                                </div>
                                {/*  Get round */}
                                <div className="row">
                                    <div className="col-3 m-2">
                                        <strong>Round</strong>
                                    </div>
                                    <div className="col-7 m-2">
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={matchRoundOptions}
                                            onChange={handleRoundChange}
                                            value={selectedRoundOption ?? ""}
                                        />
                                    </div>
                                </div>
                                {/*  Get result */}
                                <div className="row">
                                    <div className="col-3 m-2">
                                        <strong>Result</strong>
                                    </div>
                                    <div className="col-7 m-2">
                                        <input type="text" className="form-control" placeholder="Result"
                                            value={resultTxt}
                                            onChange={(x) => {
                                                setResultTxt(x.target.value);
                                                dispatch(updateVideoMetadata(
                                                    {
                                                        ...selectedVideo.linked_metadata, result: x.target.value
                                                    }));
                                            }} />
                                    </div>
                                </div>
                                {/*  Get result type */}
                                <div className="row">
                                    <div className="col-3 m-2">
                                        <strong>Result Type</strong>
                                    </div>
                                    <div className="col-7 m-2">
                                        <select className="form-control"
                                            value={selectedVideo.linked_metadata.resultType}
                                            onChange={(x) => {
                                                dispatch(updateVideoMetadata({
                                                    ...selectedVideo.linked_metadata, resultType: x.target.value
                                                }));
                                            }}>
                                            <option value={null}>Select Result Type</option>
                                            <option value="Finished">Finished</option>
                                            <option value="Retired">Retired</option>
                                            <option value="Walkover">Walkover</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary mr-1"
                                    disabled={
                                        selectedVideo?.linked_metadata.player1Id === null
                                        || selectedVideo?.linked_metadata.player2Id === null
                                        || selectedVideo?.linked_metadata.eventInstanceId === null
                                        || selectedVideo?.linked_metadata.result === null
                                        || selectedVideo?.linked_metadata.resultType === null
                                        || selectedVideo?.linked_metadata.roundId === null
                                        || selectedVideo?.linked_metadata.matchType === null
                                    } onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        $("#UpdateLinkingDataConfirmationModal").modal("show");
                                    }}>
                                    Update Meta Data
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}