import Spinner from "../../UI/Spinner";

const PlayersList = ({ players, onSelectedPlayerChange, isLoading, selectedPlayerId, count }) => {
    return (
        <div style={{ height: "70vh" }} className={`card mt-1 fs--1 px-0`} >
            <div className="card-header p-1 mb-2">
                <h3 className="m-2 text-center">{`Players (${count})`}</h3>
            </div>
            <div className="card-body px-0">
                {isLoading && <Spinner />}
                {!isLoading && players.length === 0 && <h5 className="text-center mt-5">No players found.</h5>}
                {!isLoading && players.length > 0 &&
                    <div style={{ maxHeight: "100%" }} className="list-group scrollbar perfect-scrollbar">
                        {players.map(player => {
                            let classes = "d-flex flex-column justify-content-left list-group-item list-group-item-action";
                            if (player.playerId == selectedPlayerId) {
                                classes += " bg-500 text-white";
                            }
                            return (
                                <a
                                    style={{ cursor: "pointer" }}
                                    key={player.playerId}
                                    className={classes}
                                    onClick={() => onSelectedPlayerChange(player.playerId)}
                                >
                                    <strong>{player.fullName}</strong>
                                </a>
                            );
                        })}
                    </div>
                }
            </div>
        </div>
    )

}
export default PlayersList;