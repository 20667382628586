import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const completeMatchesSlice = createApi({
  reducerPath: "completedMatchesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    mode: "cors",
    prepareHeaders: (headers) => {
      const token = getAuthToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "completedPlayers",
    "completedMatches",
    "completedMatchesPoints",
  ],
  endpoints: (builder) => ({
    getAllPlayers: builder.query({
      query: () => "/players/all",
      extraOptions: { maxRetries: 3 },
    }),
    getAllLocalPlayers: builder.query({
      query: () => "/players/all/local",
      extraOptions: { maxRetries: 3 },
    }),
    getAllHEPlayers: builder.query({
      query: () => "/players/all/he",
      extraOptions: { maxRetries: 3 },
    }),
    getEventInstances: builder.query({
      query: () => "/events/all",
      extraOptions: { maxRetries: 3 },
    }),
    getLocalEventInstances: builder.query({
      query: () => "/events/local/all",
      extraOptions: { maxRetries: 3 },
    }),
    getHETournaments: builder.query({
      query: () => "/events/he/all",
      extraOptions: { maxRetries: 3 },
    }),
    getAllCompleteMatches: builder.query({
      query: () => "/matches/complete/qa",
      providesTags: (result, error, args) => [
        "completedMatches",
      ],
      extraOptions: { maxRetries: 3 },
    }),
    updateHEPoint: builder.mutation({
      query: ({ matchInstanceId, points }) => ({
        url: `/matches/he/edit/${matchInstanceId}`,
        method: "POST",
        body: points,
      }),
      invalidatesTags: ["completedMatchesPoints"],
    }),
    setMatchInstanceStatusToReady: builder.mutation({
      query: (matchInstanceId) => ({
        url: `/matches/set/status/ready?matchInstanceId=${matchInstanceId}`,
        method: "POST"
      }),
    }),
    loadCompletedMatchPoints: builder.query({
      query: (selectedMatches) => {
        return {
          url: `/matches/complete/points/`,
          params: { selectedMatches },
        };
      },
      transformResponse: (response) => {
        //response is dict with key as matchInstanceId and value as pointList
        for (let key in response) {
          let pointList = response[key];
          for (let i = 0; i < pointList.length; i++) {
            pointList[i].isFiltered = false;
            for (let j = 0; j < pointList[i].shots.length; j++) {
              pointList[i].shots[j].isFiltered = false;
            }
          }
        }
        return response;
      },
      providesTags: (result, error, args) => [
        "completedMatchesPoints",
        args.selectedMatches ?? "",
      ],
    }),
    searchMatches: builder.query({
      query: ({ p1Id, p2Id, eventInstanceId, roundId }) => {
        if (p1Id === undefined || p1Id === null || p2Id === undefined || p2Id === null || eventInstanceId === undefined || eventInstanceId === null || roundId === undefined || roundId === null) {
          return {
            url: `/matches/search?p1Id=0&p2Id=0&eventInstanceId=0&roundId=0`,
          };
        }
        return {
          url: `/matches/search?p1Id=${p1Id}&p2Id=${p2Id}&eventInstanceId=${eventInstanceId}&roundId=${roundId}`,
        };
      },
      refetchOnMountOrArgChange: true,
      skipcache: true
    }),
    addNewMatch: builder.mutation({
      query: (match) => ({
        url: `/matches/add`,
        method: "POST",
        body: match,
      }),
    }),
    encodeVideoInstance: builder.mutation({
      query: ({ matchId }) => ({
        url: `/matches/encode?matchId=${matchId}`,
        method: "POST"
      }),
    }),
  }),
});

export const {
  useGetAllPlayersQuery,
  useGetAllLocalPlayersQuery,
  useGetAllHEPlayersQuery,
  useGetEventInstancesQuery,
  useGetLocalEventInstancesQuery,
  useGetHETournamentsQuery,
  useLoadCompletedMatchPointsQuery,
  useGetAllCompleteMatchesQuery,
  useSearchMatchesQuery,
  useUpdateHEPointMutation,
  useSetMatchInstanceStatusToReadyMutation,
  useAddNewMatchMutation,
  useEncodeVideoInstanceMutation,
} = completeMatchesSlice;
