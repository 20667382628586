import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetEventInstanceClientOpponentsQuery,
  useLazyGetEventInstanceClientsQuery,
  useGetExecutionEventsQuery,
  useGetEventInstanceReportExecutionRecordQuery,
  useRestartAutogenerationMutation
} from "../api/drawsSlice";
import { useLazyGetClientAccountQuery, useLazyGetClientSettingsQuery } from "../api/accountSlice";
import {
  setClients,
  setOpponents,
  setClientSettings,
  setReportEmailContent,
  selectedEventChanged,
  selectedClientChanged,
  selectedOpponentChanged,
  selectSelectedEvent,
  selectSelectedClient,
  selectSelectedOpponent,
  selectClients,
  selectOpponents,
  selectClientSettings,
  selectReportEmailContent,
} from "./DrawsReportsSlice";

import Spinner from "../../UI/Spinner";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import { selectIsAdmin, selectUser } from "../auth/authSlice";

import OptionsList from "./OptionsList";
import ClientDetails from "./details/ClientDetails";
import EventDetails from "./details/EventDetails";
import Reports from "../../components/reports/Reports";
import useGenerateEmail from "../../custom-hooks/useGenerateEmail";
import useShowToastr from "../../custom-hooks/useShowToastr";

const DrawsReportsPage = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const isAdmin = useSelector(selectIsAdmin);
  const selectedEvent = useSelector(selectSelectedEvent);
  const selectedClient = useSelector(selectSelectedClient);
  const selectedOpponent = useSelector(selectSelectedOpponent);
  const opponents = useSelector(selectOpponents);
  const clients = useSelector(selectClients);
  const clientSettings = useSelector(selectClientSettings);
  const reportEmailContent = useSelector(selectReportEmailContent);

  const { data: eventInstances, isLoading: loadingEvents } = useGetExecutionEventsQuery(true);

  const [getEventClients, { isLoading: loadingClients, isFetching: fetchingClients }] = useLazyGetEventInstanceClientsQuery();

  const [getClientOpponents, { isLoading: loadingOpponents, isFetching: fetchingOpponents }] = useLazyGetEventInstanceClientOpponentsQuery();

  const [getClientAccount] = useLazyGetClientAccountQuery();

  const [getClientSettings] = useLazyGetClientSettingsQuery();

  const { data: reportExecutionRecord, isLoading: loadingReportExecutionRecord, isUninitialized, refetch: refetchReportExecutionRecord } = useGetEventInstanceReportExecutionRecordQuery({ eventInstanceId: selectedEvent?.eventInstanceId, clientId: selectedClient?.clientId, opponentId: selectedOpponent?.opponentId }, { skip: !selectedOpponent });

  const { generateEmailText, isGeneratingEmail } = useGenerateEmail({ clientName: selectedClient?.clientName, opponentName: selectedOpponent?.opponentName, clientId: selectedClient?.clientId, opponentId: selectedOpponent?.opponentId, clientLocalId: selectedClient?.clientLocalId, opponentLocalId: selectedOpponent?.opponentLocalId, stdReports: reportExecutionRecord?.stdReports, surfaceTypeId: reportExecutionRecord?.surfaceTypeId });

  const [restartAutogeneration, { isLoading: isRestartingAutogeneration, isSuccess: autogenerationSuccess, isError: autogenerationFailed }] = useRestartAutogenerationMutation();

  useShowToastr({ isSuccess: autogenerationSuccess, isError: autogenerationFailed, successMsg: "Report generation restarted successfully", errorMsg: "Failed to restart report generation" });

  useEffect(() => {
    let interval;
    if (!isUninitialized && reportExecutionRecord && refetchReportExecutionRecord) {
      interval = setInterval(async () => {
        await refetchReportExecutionRecord();
      }, 3000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isUninitialized, reportExecutionRecord, refetchReportExecutionRecord]);

  const fetchEmailText = async () => {
    const text = await generateEmailText();
    if (text) {
      dispatch(setReportEmailContent(text));
    }
  }

  const eventChangedHandler = async (eventId) => {
    const event = eventInstances.find(
      (event) => event.eventInstanceId === eventId
    );
    dispatch(selectedEventChanged(event));
    const response = await getEventClients(eventId)
    dispatch(setClients(response.data))
  };

  const clientChangedHandler = async (clientId) => {
    const client = clients.find((client) => client.clientId === clientId);
    dispatch(selectedClientChanged(client));
    if (client.isActive === false) {
      dispatch(setOpponents([]));
      return;
    }
    const response = await getClientOpponents({ eventInstanceId: selectedEvent?.eventInstanceId, clientId: clientId, })
    dispatch(setOpponents(response.data))

    const accountResponse = await getClientAccount(clientId);
    const clientSettingsResponse = await getClientSettings(accountResponse.data.accountId);
    dispatch(setClientSettings(clientSettingsResponse.data));
  };

  const opponentChangedHandler = async (opponentId) => {
    const opponent = opponents.find(
      (opponent) => opponent.opponentId === opponentId
    );
    dispatch(selectedOpponentChanged(opponent));
    dispatch(setReportEmailContent(""));
  };

  if (!user) return <Spinner />;
  else if (!isAdmin) return <NotAuthorizedPage />;

  return (
    <div className="d-flex flex-column">
      {loadingEvents && <Spinner />}
      {eventInstances && (
        <div className="row mt-2 flex-nowrap" style={{ overflowX: "hidden", overflowY: "hidden" }}>
          <OptionsList
            title="Events"
            options={eventInstances.map((eventInstance) => ({
              label: eventInstance.eventName,
              value: eventInstance.eventInstanceId,
              status: eventInstance.needsReview
            }))}
            selectedOption={selectedEvent?.eventInstanceId}
            setSelectedOption={eventChangedHandler}
          />
          {
            <OptionsList
              title="Clients"
              options={clients?.map((client) => ({
                label: client.clientName,
                value: client.clientId,
                clientRound: client.round,
                isActive: client.isActive
              }))}
              selectedOption={selectedClient?.clientId}
              setSelectedOption={clientChangedHandler}
              isLoading={loadingClients || fetchingClients}
            />
          }
          {
            <OptionsList
              title="Opponents"
              options={opponents?.map((opponent) => ({
                label: opponent.opponentName,
                value: opponent.opponentId,
                opponentRound: opponent.round,
              }))}
              selectedOption={selectedOpponent?.opponentId}
              setSelectedOption={opponentChangedHandler}
              isLoading={loadingOpponents || fetchingOpponents}
            />
          }
          {(loadingReportExecutionRecord) && <Spinner />}

          {selectedEvent && !selectedClient && !selectedOpponent && (
            <div className="d-flex flex-column col-9 mt-1">
              <EventDetails selectedEvent={selectedEvent} />
            </div>
          )}
          {selectedClient && !selectedOpponent && (
            <div className="d-flex flex-column col-9 mt-1">
              <ClientDetails selectedEvent={selectedEvent} selectedClient={selectedClient} />
            </div>
          )}
          {!loadingReportExecutionRecord && selectedOpponent && reportExecutionRecord && (
            <div className="d-flex flex-column col-9 mt-1">
              <button style={{ width: "fit-content" }} className="btn btn-sm btn-warning mb-2" onClick={() => restartAutogeneration({ id: reportExecutionRecord.eventInstanceReportRecordId })} disabled={isRestartingAutogeneration}>Restart Report Generation</button>
              <Reports reportExecutionRecord={reportExecutionRecord} target={{ localName: selectedOpponent.opponentLocalName, localPlayerId: selectedOpponent.opponentLocalId, fullName: selectedOpponent.opponentName  }} clientSettings={clientSettings} generateEmailText={fetchEmailText} isGeneratingEmail={isGeneratingEmail} reportEmailContent={reportEmailContent} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DrawsReportsPage;
