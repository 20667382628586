import Spinner from "../../UI/Spinner";

const VideosLinkingList = ({ videos, onSelectedVideoChange, isLoading, selectedVideoId, count }) => {
    return (
        <div className={`card mt-1 fs--1 px-0`} >
            <div className="card-body px-0">
                {isLoading && <Spinner />}
                {!isLoading && videos.length === 0 && <h5 className="text-center mt-5">No videos found.</h5>}
                {!isLoading && videos.length > 0 &&
                    <div style={{ maxHeight: "100%" }} className="list-group scrollbar perfect-scrollbar">

                        {videos.map(video => {
                            let classes = "d-flex flex-column justify-content-left list-group-item list-group-item-action";
                            if (video.id == selectedVideoId) {
                                classes += " bg-500 text-white";
                            }
                            return (
                                <a
                                    style={{ cursor: "pointer" }}
                                    key={video.id}
                                    className={classes}
                                    onClick={() => onSelectedVideoChange(video)}
                                >
                                    <strong>{video.title}</strong>
                                    <div className='d-flex row ml-auto'>
                                        {video.isCvCompleted === true && (
                                            <span className="badge badge-success ml-2">CV</span>
                                        )}
                                        {video.isCvNew === true && (
                                            <span className="badge badge-danger ml-2">CV</span>
                                        )}
                                        {video.isCvProcessing === true && (
                                            <span className="badge badge-warning ml-2">CV</span>
                                        )}
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                }
            </div>
        </div>
    )

}
export default VideosLinkingList;