import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const editorApiSlie = createApi({
    reducerPath: "editorapi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/editor`,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        postCompareRequest: builder.mutation({
            query: (body) => ({
                url: `/snapshots/compare`,
                method: "POST",
                body,
            }),
            invalidatesTags: []
        }),
        updateSnapshotMetadata: builder.mutation({
            query: ({ snapshotId, match }) => ({
                url: `/snapshots/${snapshotId}/metadata`,
                method: "PUT",
                body: match,
            }),
        }),
        updateSnapshotPoint: builder.mutation({
            query: ({ snapshotId, point }) => ({
                url: `/snapshots/${snapshotId}/points/${point.id}`,
                method: "PUT",
                body: point,
            }),
        }),
        commitMatchSnapshot: builder.mutation({
            query: ({ videoProcessId, match }) => ({
                url: `/${videoProcessId}/snapshots`,
                method: "POST",
                body: match,
            })
        })
    }),
});

export const {
    usePostCompareRequestMutation,
    useUpdateSnapshotMetadataMutation,
    useUpdateSnapshotPointMutation,
    useCommitMatchSnapshotMutation
} = editorApiSlie;
