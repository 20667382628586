import React, { useState } from "react";
import { useGetVideosQuery, useAddVideoMutation } from "../api/aoVideosSlice";
import "./AoVideosPage.module.css";
import classes from "./AoVideosPage.module.css";

const AoVideosPage = () => {
  const { data: videos, refetch } = useGetVideosQuery();
  const [addVideo] = useAddVideoMutation();
  const [formData, setFormData] = useState({
    url: "",
    title: "",
    is_atp: true,
    id: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "isAtp" ? value === "ATP" : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const urlPattern = /\/(\w+)\.mp4$/;
    const match = urlPattern.exec(formData.url);
    const id = match ? match[1] : "";
    setFormData({
      ...formData,
      id: id,
    });
    await addVideo(formData);
    refetch();
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit} className="form">
        <div className={classes["form-group"]}>
          <label htmlFor="url">URL</label>
          <input
            type="text"
            name="url"
            placeholder="URL"
            value={formData.url}
            onChange={handleChange}
            required
          />
        </div>
        <div className={classes["form-group"]}>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            name="title"
            placeholder="Title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className={classes["radio-group"]}>
          <label>
            <input
              type="radio"
              name="isAtp"
              value="ATP"
              checked={formData.isAtp === true}
              onChange={handleChange}
              required
            />
            ATP
          </label>
          <label>
            <input
              type="radio"
              name="isAtp"
              value="WTA"
              checked={formData.isAtp === false}
              onChange={handleChange}
              required
            />
            WTA
          </label>
        </div>
        <button className={classes['AObutton']} type="submit">Submit</button>
      </form>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">URL</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {videos?.map((video) => (
            <tr key={video.id}>
              <td>{video.title}</td>
              <td>
                <a href={video.url} target="_blank" rel="noopener noreferrer">
                  {video.url}
                </a>
              </td>
              <td>{video.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AoVideosPage;
