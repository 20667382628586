
import { useSelector, useDispatch } from "react-redux";
import { selectMatch, editMatch } from "./store/editorSlice";
import { speedUnitOptions, numberOfSetsOptions, tieBreakFinalSetOptions, typeOfScoringOptions, matchFormatsOptions } from "./Utils/options";
import ViewButton from "../../UI/Buttons/ViewButton";
import ButtonGroup from "../../components/ButtonGroup";
import { isEmptyField } from "./Utils/shotUtils";
import useShowToastr from "../../custom-hooks/useShowToastr";
import { useUpdateSnapshotMetadataMutation } from "../api/editorApiSlice";

const EditMatch = ({ videoProcessId, snapshotId }) => {
    const match = useSelector(selectMatch);
    const dispatch = useDispatch();

    const [updateSnapshotMetadata, { isLoading, isSuccess, isError }] = useUpdateSnapshotMetadataMutation();

    useShowToastr({
        isSuccess: isSuccess,
        isError: isError,
        title: "Update Snapshot Metadata",
        successMsg: "Snapshot metadata updated successfully",
        errorMsg: "Failed to update snapshot metadata",
        modalId: "matchDetailsModal",
    });

    const optionChangedHandler = (field, value) => {
        const updatedMatch = { ...match, [field]: value };
        dispatch(editMatch(updatedMatch));
    }

    const matchDetailsClickHandler = () => {
        $("#matchDetailsModal").modal("show");
    }

    return (
        <div className='row align-items-center'>
            <ViewButton onClick={matchDetailsClickHandler} extraClasses="btn-sm ml-3" extraStyles={{ width: "fit-content", height: "fit-content" }} isDisabled={false}>
                <span className="ml-2">Match Details</span>
            </ViewButton >
            <div
                className="modal fade"
                id="matchDetailsModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="matchDetailsModalLabel"
                aria-hidden="true"
            >
                <div style={{ minWidth: "40%" }} className="modal-dialog" role="document">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="matchDetailsModalLabel">
                                Match Details
                            </h5>
                        </div>
                        <div style={{ maxHeight: "70vh" }} className="modal-body">

                            <div className="form-inline row my-2 flex-nowrap">
                                <label className={`text-center col-4 ${isEmptyField(match.speedUnit, speedUnitOptions) ? "text-danger" : ""}`} htmlFor="speedUnit">Speed Unit</label>
                                <ButtonGroup field="speedUnit" value={match.speedUnit} options={speedUnitOptions} onOptionChanged={optionChangedHandler} />
                            </div>

                            <div className="form-inline row my-2 flex-nowrap">
                                <label className={`text-center col-4 ${isEmptyField(match.numberOfSets, numberOfSetsOptions) ? "text-danger" : ""}`} htmlFor="numberOfSets">Number of Sets</label>
                                <ButtonGroup field="numberOfSets" value={match.numberOfSets} options={numberOfSetsOptions} onOptionChanged={optionChangedHandler} />
                            </div>

                            <div className="form-inline row my-2 flex-nowrap">
                                <label className={`text-center col-4 ${isEmptyField(match.tieBreakFinalSet, tieBreakFinalSetOptions) ? "text-danger" : ""}`} htmlFor="tieBreakFinalSet">Tie Break Final Set</label>
                                <ButtonGroup field="tieBreakFinalSet" value={match.tieBreakFinalSet} options={tieBreakFinalSetOptions} onOptionChanged={optionChangedHandler} />
                            </div>

                            <div className="form-inline row my-2 flex-nowrap">
                                <label className={`text-center col-4 ${isEmptyField(match.typeOfScoring, typeOfScoringOptions) ? "text-danger" : ""}`} htmlFor="typeOfScoring">Type Of Scoring</label>
                                <ButtonGroup field="typeOfScoring" value={match.typeOfScoring} options={typeOfScoringOptions} onOptionChanged={optionChangedHandler} />
                            </div>
                            <div className="form-inline row my-2 flex-nowrap">
                                <label className={`text-center col-4 ${isEmptyField(match.matchFormat, matchFormatsOptions) ? "text-danger" : ""}`} htmlFor="matchFormat">Match Format</label>
                                <select className="form-control" id="matchFormat" value={match.matchFormat} onChange={(e) => optionChangedHandler("matchFormat", e.target.value)}>
                                    {matchFormatsOptions.map((option, index) => <option key={index} value={option.value}>{option.label}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-warning"
                                disabled={isLoading}
                                onClick={() => updateSnapshotMetadata({ snapshotId: snapshotId, match: { ...match, points: [] } })}
                            >
                                {isLoading ? "Updating..." : "Update"}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditMatch;