import { Tabs, Placeholder } from 'rsuite';
import { useGetPlayerDetailsQuery } from '../api/playerSlice';
import { useGetPlayerMatchesQuery } from '../api/matchesSlice';

const IMGAPlayerDetails = ({ player }) => {

    const { data: playerDetails, isLoading: isPlayerLoading } = useGetPlayerDetailsQuery(player.playerId);
    const { data: playerMatches, isLoading: isMatchesLoading } = useGetPlayerMatchesQuery(player.playerId);

    return (
        <div>
            <h1>{player.fullName}</h1>
            <Tabs defaultActiveKey="profile">
                <Tabs.Item eventKey="profile" title="Profile">
                    {isPlayerLoading && <Placeholder.Paragraph row={3} />}
                    {!isPlayerLoading && playerDetails && (
                        <div>
                            <p>First Name: {playerDetails.firstName}</p>
                            <p>Last Name: {playerDetails.lastName}</p>
                            <p>Handedness: {playerDetails.dominantHandName}</p>
                            <p>DOB: {playerDetails.dob}</p>
                            <p>Age: {playerDetails.age}</p>
                            <p>Gender: {playerDetails.gender}</p>
                        </div>
                    )}
                </Tabs.Item>
                <Tabs.Item eventKey="matches" title="Matches">
                    {isMatchesLoading && <Placeholder.Paragraph rows={5} />}
                    {!isMatchesLoading && playerMatches && (
                        <div>
                            <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Tournament</th>
                                        <th>P1</th>
                                        <th>P2</th>
                                        <th>Winner</th>
                                        <th>Round</th>
                                        <th>Instances</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {playerMatches.map((match) => (
                                        <tr key={match.matchId}>
                                            <td>{match.matchId}</td>
                                            <td>{match.eventInstanceName}</td>
                                            <td>{match.playerName1}</td>
                                            <td>{match.playerName2}</td>
                                            <td>{match.winner}</td>
                                            <td>{match.roundName}</td>
                                            <td>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Id</th>
                                                            <th>Format</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {match.matchInstances.map((instance) => (
                                                            <tr key={instance.matchInstanceId}>
                                                                <td>{instance.matchInstanceId}</td>
                                                                <td>{instance.format}</td>
                                                                <td>{instance.status}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </Tabs.Item>
                <Tabs.Item eventKey="reports" title="Reports">
                    <Placeholder.Paragraph rows={5} />
                </Tabs.Item>
            </Tabs>
        </div>
    );
}

export default IMGAPlayerDetails;