import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const matchesSlice = createApi({
    reducerPath: "matchesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/matches`,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getPlayerMatches: builder.query({
            query: (playerId) => `/player/${playerId}`,
        }),
        trimMatchVideo: builder.mutation({
            query: (matchId) => ({
                url: `/trim/${matchId}`,
                method: "POST",
            }),
        }),
    })
});

export const {
    useGetPlayerMatchesQuery,
    useTrimMatchVideoMutation
} = matchesSlice;