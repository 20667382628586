import { useEffect, useReducer, useMemo } from "react";
import { useSelector } from "react-redux";

import { selectUser, selectIsAdmin } from "../auth/authSlice";
import { useGetAllPlayersQuery } from "../api/completeMatchesSlice";
import { useGetClientsQuery } from "../api/apiSlice";
import { useLazyGetClientOpponentsQuery, useGetClientsRecordsQuery, useGetExecutionRecordQuery, useCreateInternalClientMutation, useDeactivateInternalClientMutation, useRestartAutogenerationMutation } from "../api/reportsSlice";
import { useLazyGetClientAccountQuery, useLazyGetClientSettingsQuery } from "../api/accountSlice";
import useShowToastr from "../../custom-hooks/useShowToastr";
import OptionsList from "../../components/OptionsList";
import Spinner from "../../UI/Spinner";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import NewRecord from "./NewRecords";
import Reports from "../../components/reports/Reports";
import useGenerateEmail from "../../custom-hooks/useGenerateEmail";

const clientsReportsReducer = (state, action) => {
    switch (action.type) {
        case "SET_OPPONENTS":
            return {
                ...state,
                opponents: action.opponents,

            };
        case "SET_SELECTED_CLIENT":
            return {
                ...state,
                selectedClient: action.selectedClient,
                selectedOpponent: null,
            };
        case "SET_SELECTED_OPPONENT":
            return {
                ...state,
                selectedOpponent: action.selectedOpponent,
            };
        case "SET_CLIENT_OPTIONS":
            return {
                ...state,
                clientOptions: action.clientOptions,
            };
        case "SET_OPPONENT_OPTIONS":
            return {
                ...state,
                opponentOptions: action.opponentOptions,
            };
        case "DEACTIVATE_REPORT":
            return {
                ...state,
                opponents: action.opponents,
                selectedOpponent: null,
            };
        case "SET_CLIENT_SETTINGS":
            return {
                ...state,
                clientSettings: action.clientSettings,
            };
        case "SET_EMAIL_CONTENT":
            return {
                ...state,
                reportEmailContent: action.reportEmailContent,
            };
        default:
            return state;
    }
};

const ClientsReportPage = () => {
    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const clientReducer = useReducer(clientsReportsReducer, {
        selectedClient: null,
        selectedOpponent: null,
        opponents: null,
        clientOptions: null,
        opponentOptions: null,
        clientSettings: null,
        reportEmailContent: "",
    });

    const [{ selectedClient, selectedOpponent, opponents, clientOptions, opponentOptions, clientSettings, reportEmailContent }, dispatch] = clientReducer;

    const { data: clients, isLoading: loadingClients, refetch: refetchClientsRecords } = useGetClientsRecordsQuery(true);

    const [getClientOpponents, { isLoading: loadingOpponents, isFetching: fetchingOpponents }] = useLazyGetClientOpponentsQuery();

    const [getClientAccount] = useLazyGetClientAccountQuery();

    const [getClientSettings] = useLazyGetClientSettingsQuery();

    const { data: reportExecutionRecord, isLoading: loadingReportExecutionRecord, isUninitialized, refetch: refetchReportExecutionRecord } = useGetExecutionRecordQuery(selectedOpponent ? selectedOpponent.id : selectedClient?.id, { skip: !selectedOpponent && !selectedClient });

    const { data: allPlayers } = useGetAllPlayersQuery();
    const { data: allClients } = useGetClientsQuery();

    const [createRecord, { isLoading: loadingRecordCreation, isSuccess: createdSuccessfully, isError: creationError }] = useCreateInternalClientMutation();
    const [deactivateRecord, { isSuccess: deactivatedSuccessfully, isError: deactivationError }] = useDeactivateInternalClientMutation();

    const { generateEmailText, isGeneratingEmail } = useGenerateEmail({ clientName: selectedClient?.clientName, opponentName: selectedOpponent?.opponentName, clientId: selectedClient?.clientId, opponentId: selectedOpponent?.opponentId, clientLocalId: selectedClient?.clientLocalId, opponentLocalId: selectedOpponent?.opponentLocalId, stdReports: reportExecutionRecord?.stdReports, surfaceTypeId: reportExecutionRecord?.surfaceTypeId });

    useShowToastr({ isSuccess: createdSuccessfully, isError: creationError, title: "Create New Record", successMsg: "Created Successfully!", errorMsg: "Failed to create!" });
    useShowToastr({ isSuccess: deactivatedSuccessfully, isError: deactivationError, title: "Deactivate Report", successMsg: "Deactivated Successfully!", errorMsg: "Failed to deactivate!" });

    const [restartAutogeneration, { isLoading: isRestartingAutogeneration, isSuccess: autogenerationSuccess, isError: autogenerationFailed }] = useRestartAutogenerationMutation();

    useShowToastr({ isSuccess: autogenerationSuccess, isError: autogenerationFailed, successMsg: "Report generation restarted successfully", errorMsg: "Failed to restart report generation" });

    useEffect(() => {
        if (allClients && clients) {
            const clientOptions = allClients
                .filter(x => !clients?.some(client => client.clientId === x.clientId))
                .map((client) => ({
                    value: client.clientId,
                    label: client.name + ' [' + client.category + ']',
                }));
            dispatch({ type: "SET_CLIENT_OPTIONS", clientOptions });
        }
    }, [allClients, clients]);

    useEffect(() => {
        if (allPlayers && opponents && selectedClient) {
            const opponentOptions = allPlayers
                .filter(player => player.category == selectedClient.category)
                .map((client) => ({
                    value: client.playerId,
                    label: client.name,
                }));
            dispatch({ type: "SET_OPPONENT_OPTIONS", opponentOptions });
        }
    }, [allPlayers, opponents, selectedClient]);

    useEffect(() => {
        let interval;
        if (!isUninitialized && reportExecutionRecord && refetchReportExecutionRecord) {
            interval = setInterval(async () => {
                await refetchReportExecutionRecord();
            }, 3000);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isUninitialized, reportExecutionRecord, refetchReportExecutionRecord]);

    const fetchEmailText = async () => {
        const text = await generateEmailText();
        if (text) {
            dispatch({ type: "SET_EMAIL_CONTENT", reportEmailContent: text });
        }
    }

    const clientChangedHandler = async (clientId) => {
        const client = clients.find((client) => client.clientId === clientId);
        dispatch({ type: "SET_SELECTED_CLIENT", selectedClient: client });
        const response = await getClientOpponents(clientId);
        dispatch({ type: "SET_OPPONENTS", opponents: response.data });

        const accountResponse = await getClientAccount(clientId);
        const clientSettingsResponse = await getClientSettings(accountResponse.data.accountId);
        dispatch({ type: "SET_CLIENT_SETTINGS", clientSettings: clientSettingsResponse.data });
    };

    const opponentChangedHandler = async (opponentId) => {
        const opponent = opponents.find(
            (opponent) => opponent.opponentId === opponentId
        );
        dispatch({ type: "SET_SELECTED_OPPONENT", selectedOpponent: opponent });
        dispatch({ type: "SET_EMAIL_CONTENT", reportEmailContent: "" });
    };

    const addNewClientHandler = async (data) => {
        const payload = {
            clientId: data.playerId
        };
        await createRecord(payload);
        await refetchClientsRecords(true);
        $('#AddNewRecordModalClients').modal('hide');
    }
    const addNewOpponentHandler = async (data) => {
        const payload = {
            clientId: selectedClient.clientId,
            opponentId: data.playerId,
            surfaceTypeId: data.surfaceId
        }
        await createRecord(payload);
        const response = await getClientOpponents(selectedClient.clientId);
        dispatch({ type: "SET_OPPONENTS", opponents: response.data });
        $('#AddNewRecordModalOpponents').modal('hide');
    }

    const deactivationHandler = async () => {
        await deactivateRecord(selectedOpponent.id);
        const response = await getClientOpponents(selectedClient.clientId);
        dispatch({ type: "DEACTIVATE_REPORT", opponents: response.data });
    }

    const clientsList = useMemo(() => {
        const categoryMap = {
            1: 'ATP',
            2: 'WTA',
            4: 'IMG'
        };
    
        return clients?.map((client) => {
            const categoryLabel = categoryMap[client.category] || client.category; // Default to the original value if no mapping found
            return {
                label: `${client.clientName} [${categoryLabel}]`,
                value: client.clientId,
                isPlaying: client.isPlaying
            };
        });
    }, [clients]);
    

    const opponentsList = useMemo(() => {
        return opponents?.map((opponent) => ({
            label: opponent.opponentName,
            value: opponent.opponentId,
            tag: opponent.surface,
            isPlaying: opponent.isPlaying
        }));
    }, [opponents]);


    if (!user) return <Spinner />;
    else if (!isAdmin) return <NotAuthorizedPage />;

    return (
        <div className="d-flex flex-column">
            <div className="ml-auto mr-5" style={{ display: "flex", gap: "10px" }}>
                <button
                    className="btn btn-danger rounded-capsule ml-auto"
                    type="button"
                    disabled={!reportExecutionRecord || !selectedOpponent}
                    onClick={deactivationHandler}
                >
                    Deactivate
                    <i className="fas fa-minus-circle ml-2"></i>
                </button>
            </div>
            {loadingClients && <Spinner />}
            {clients && (
                <div className="row mt-2 flex-nowrap" style={{ overflowX: "hidden", overflowY: "hidden" }}>
                    <NewRecord title="Clients" onCreateRecord={addNewClientHandler} playerOptions={clientOptions} isCreating={loadingRecordCreation} />
                    <NewRecord title="Opponents" onCreateRecord={addNewOpponentHandler} playerOptions={opponentOptions} isCreating={loadingRecordCreation} />
                    <OptionsList
                        title="Clients"
                        options={clientsList}
                        selectedOption={selectedClient?.clientId}
                        setSelectedOption={clientChangedHandler}
                        isLoading={loadingClients}
                        modalId="AddNewRecordModalClients"
                    />
                    <OptionsList
                        title="Opponents"
                        options={opponentsList}
                        selectedOption={selectedOpponent?.opponentId}
                        setSelectedOption={opponentChangedHandler}
                        isLoading={loadingOpponents || fetchingOpponents}
                        modalId="AddNewRecordModalOpponents"
                    />
                    {(loadingReportExecutionRecord) && <Spinner />}

                    {!loadingReportExecutionRecord && selectedClient && reportExecutionRecord && (
                        <div className="d-flex flex-column col-8 mt-1">
                            {selectedOpponent && <button style={{ width: "fit-content" }} className="btn btn-sm btn-warning mb-2" onClick={() => restartAutogeneration({ id: reportExecutionRecord.internalClientReportId })} disabled={isRestartingAutogeneration}>Restart Report Generation</button>}
                            <Reports reportExecutionRecord={reportExecutionRecord} target={{ localName: selectedOpponent ? selectedOpponent.opponentLocalName : selectedClient.clientLocalName, localPlayerId: selectedOpponent ? selectedOpponent.opponentLocalId : selectedClient.clientLocalId, fullName: selectedOpponent ? selectedOpponent.opponentName : selectedClient.clientName }} clientSettings={clientSettings} reportEmailContent={reportEmailContent} isGeneratingEmail={isGeneratingEmail} generateEmailText={selectedOpponent ? fetchEmailText : null} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );

}

export default ClientsReportPage;