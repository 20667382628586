import { useEffect, useState } from 'react';
import { Tabs, Placeholder } from 'rsuite';
import { useGetPlayerDetailsQuery, useCreateReportRecordMutation, useGetPlayerLookupsQuery, useCreateScoutingReportsMutation } from '../api/playerSlice';
import { useGetPlayerExecutionRecordQuery } from '../api/reportsSlice';
import { useGetPlayerMatchesQuery } from '../api/matchesSlice';
import Spinner from "../../UI/Spinner";
import Reports from "../../components/reports/Reports";
import { CopyToClipboard } from 'react-copy-to-clipboard';


const PlayerDetails = ({ playerId }) => {
    const [reportRecordExist, setReportRecordExist] = useState(false);
    const [trimmedVideos, setTrimmedVideos] = useState([]);
    const { data: playerDetails, isLoading: isPlayerLoading } = useGetPlayerDetailsQuery(playerId);
    const { data: playerMatches, isLoading: isMatchesLoading } = useGetPlayerMatchesQuery(playerId);
    const { data: playerLookups, isLoading: isLookupsLoading } = useGetPlayerLookupsQuery(playerId);
    const { data: playerExecutionRecord, isUninitialized, isLoading: isReportRecordLoading, refetch: refetchReportRecord } = useGetPlayerExecutionRecordQuery(playerId);
    const [createReportRecord] = useCreateReportRecordMutation();
    const [createScoutingReports] = useCreateScoutingReportsMutation();

    useEffect(() => {
        let interval;
        if (!isUninitialized && playerExecutionRecord && refetchReportRecord) {
            interval = setInterval(async () => {
                await refetchReportRecord();
            }, 3000);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isUninitialized, playerExecutionRecord, refetchReportRecord]);

    useEffect(() => {
        if (playerMatches) {
            let videos = [];
            playerMatches.forEach((match) => {
                match.matchInstances.forEach((instance) => {
                    if (instance.format == 'TVID') {
                        videos.push({ matchId: match.matchId, eventInstanceId: match.eventInstanceId, tournament: match.eventInstanceName, matchInstanceId: instance.matchInstanceId, status: instance.status, p1: match.playerName1, p2: match.playerName2 });
                    }
                });
            });
            setTrimmedVideos(videos);
        }
    }, [playerMatches]);


    useEffect(() => {
        if (playerExecutionRecord) {
            setReportRecordExist(true);
        }
    }, [playerExecutionRecord]);

    if (isPlayerLoading || isMatchesLoading || isReportRecordLoading) {
        return <Spinner />;
    }

    return (
        <div>
            <h1>{playerDetails && playerDetails.fullName}</h1>
            <Tabs defaultActiveKey="profile">
                <Tabs.Item eventKey="profile" title="Profile">
                    {isPlayerLoading && <Placeholder.Paragraph row={3} />}
                    {!isPlayerLoading && playerDetails && (
                        <div>
                            <p>First Name: {playerDetails.firstName}</p>
                            <p>Last Name: {playerDetails.lastName}</p>
                            <p>Handedness: {playerDetails.dominantHandName}</p>
                            <p>DOB: {playerDetails.dob}</p>
                            <p>Age: {playerDetails.age}</p>
                            <p>Gender: {playerDetails.gender}</p>
                        </div>
                    )}
                </Tabs.Item>
                <Tabs.Item eventKey="lookups" title="Lookups">
                    {isLookupsLoading && <Placeholder.Paragraph rows={5} />}
                    {!isLookupsLoading && playerLookups && (
                        <div>
                            <table border="1" cellPadding="10" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>Source</th>
                                        <th>Source Name</th>
                                        <th>Source ID</th>
                                        <th>Score</th>
                                        <th>Manual Review Needed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {playerLookups.lookups.map((lookup, index) => (
                                        <tr key={index}>
                                            <td>{lookup.source}</td>
                                            <td>{lookup.sourceName}</td>
                                            <td>{lookup.sourceId}</td>
                                            <td>{lookup.score}</td>
                                            <td>{lookup.isManualReviewNeeded ? 'Yes' : 'No'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </Tabs.Item>
                <Tabs.Item eventKey="matches" title={`Matches (${playerMatches.length})`}>
                    {isMatchesLoading && <Placeholder.Paragraph rows={5} />}
                    {!isMatchesLoading && playerMatches && (
                        <div className="card-body" style={{ overflowY: "auto", maxHeight: "700px" }}>
                            <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Tournament</th>
                                        <th>P1</th>
                                        <th>P2</th>
                                        <th>Winner</th>
                                        <th>Round</th>
                                        <th>Instances</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {playerMatches.map((match) => (
                                        <tr key={match.matchId}>
                                            <td>{match.matchId}</td>
                                            <td>{match.eventInstanceName}</td>
                                            <td>{match.playerName1}</td>
                                            <td>{match.playerName2}</td>
                                            <td>{match.winner}</td>
                                            <td>{match.roundName}</td>
                                            <td>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Id</th>
                                                            <th>Format</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {match.matchInstances.map((instance) => (
                                                            <tr key={instance.matchInstanceId}>
                                                                <td>{instance.matchInstanceId}</td>
                                                                <td>{instance.format}</td>
                                                                <td>{instance.status}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </Tabs.Item>
                <Tabs.Item eventKey="trimmedVideos" title={`Trimmed Videos ${trimmedVideos.length}`}>
                    {isMatchesLoading && <Placeholder.Paragraph rows={5} />}
                    {!isMatchesLoading && playerMatches && (
                        <div className="card-body" style={{ overflowY: "auto", maxHeight: "700px" }}>
                            <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tournament</th>
                                        <th>P1</th>
                                        <th>P2</th>
                                        <th>Path</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trimmedVideos.map((video) => (
                                        <tr key={video.matchInstanceId}>
                                            <td>{video.matchInstanceId}</td>
                                            <td>{video.tournament}</td>
                                            <td>{video.p1}</td>
                                            <td>{video.p2}</td>
                                            <td>{`full/${video.eventInstanceId}/${video.matchId}/${video.matchInstanceId}.mp4`}</td>
                                            <td>{video.status}</td>
                                            <td>
                                                <CopyToClipboard text={`https://gsa-video.azureedge.net/full/${video.eventInstanceId}/${video.matchId}/${video.matchInstanceId}.mp4?sv=2022-11-02&ss=b&srt=sco&sp=rlx&se=2025-12-30T23:35:54Z&st=2023-12-28T15:35:54Z&spr=https&sig=s1UPkZp2UFuQK1vjzjYjQSPDgZ73gh%2FbsQopO53mzxo%3D`}>
                                                    <button className="btn btn-primary mr-1 mb-1 fs--1" type="button">Browser URL</button>
                                                </CopyToClipboard>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </Tabs.Item>
                <Tabs.Item eventKey="reports" title="Reports">
                    {isReportRecordLoading && <Placeholder.Paragraph rows={5} />}
                    {!isReportRecordLoading && !isPlayerLoading && playerDetails && playerExecutionRecord && playerExecutionRecord.stdReports.length == 0 &&  (
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                createScoutingReports(playerId)
                                    .then(() => {
                                        refetchReportRecord();
                                    });
                            }
                            }
                        >
                            Generate Default Scouting Reports
                        </button>
                    )}
                    <hr />
                    {!isReportRecordLoading && !isPlayerLoading && playerDetails && playerExecutionRecord && (
                        <div>
                            <Reports reportExecutionRecord={playerExecutionRecord} target={{ localName: playerDetails.LocalName, localPlayerId: playerDetails.localId, fullName: playerDetails.fullName }} isUserReport={true} />
                        </div>
                    )}
                    {!reportRecordExist && (
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                createReportRecord(playerId)
                                    .then(() => {
                                        refetchReportRecord();
                                    });
                            }
                            }
                        >
                            Generate Report
                        </button>
                    )}
                </Tabs.Item>
            </Tabs>
        </div>
    );
}

export default PlayerDetails;