import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    videos : [],
    filteredVideos: [],
    selectedVideo: null,
    detailedVideo: null,
    selectedVideoId: null,
    searchP1: "",
    searchP2: "",
    searchTournament: "",
    pageNumber: 1,
    pageSize: 10,
    totalCount: 0,
    currentPage: 1
};

const videosLinkingSlice = createSlice({
    name: "videosLinking",
    initialState,
    reducers: {
        setVideos(state, action) {
            state.videos = action.payload.videos;
            state.filteredVideos = action.payload.videos;
            state.totalCount = action.payload.totalCount;
        },
        setSelectedVideo(state, action) {
            state.selectedVideo = action.payload;
        },
        setDetailedVideo(state, action) {
            state.detailedVideo = action.payload;
        },
        setSearchP1(state, action) {
            state.searchP1 = action.payload;
        },
        setSearchP2(state, action) {
            state.searchP2 = action.payload;
        },
        setSearchTournament(state, action) {
            state.searchTournament = action.payload;
        },
        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setTotalCount(state, action) {
            state.totalCount = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
        reset(state, action) {
            state.videos = [];
            state.filteredVideos = [];
            state.selectedVideo = null;
        },
        filterVideosByTitle(state, action) {
            let keywords = action.payload.split(" ");
            //search for each keyword in the title
            let result = state.videos.filter(video => {
                let title = video.title.toLowerCase();
                return keywords.every(keyword => title.includes(keyword.toLowerCase()));
            });
            // let result = state.videos.filter(video => video.title.toLowerCase().includes(action.payload.toLowerCase()));
            state.filteredVideos = result;
        }
    },
});


export const { 
    setVideos, 
    setSelectedVideo, 
    reset, 
    filterVideosByTitle, 
    setCurrentPage,
    setSearchP1,
    setSearchP2,
    setSearchTournament,
    setPageNumber,
    setPageSize,
    setTotalCount,
    setDetailedVideo
} = videosLinkingSlice.actions;
export const selectVideos = (state) => state.videosLinking.videos;
export const selectSelectedVideo = (state) => state.videosLinking.selectedVideo;
export const selectFilteredVideos = (state) => state.videosLinking.filteredVideos;
export const selectSearchP1 = (state) => state.videosLinking.searchP1;
export const selectSearchP2 = (state) => state.videosLinking.searchP2;
export const selectSearchTournament = (state) => state.videosLinking.searchTournament;
export const selectPageNumber = (state) => state.videosLinking.pageNumber;
export const selectPageSize = (state) => state.videosLinking.pageSize;
export const selectTotalCount = (state) => state.videosLinking.totalCount;
export const selectCurrentPage = (state) => state.videosLinking.currentPage;
export const selectDetailedVideo = (state) => state.videosLinking.detailedVideo;

export default videosLinkingSlice.reducer;