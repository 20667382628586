import React, { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { selectUser, selectIsAdmin } from '../auth/authSlice';
import { useLazyGetPlayersQuery } from '../api/playerSlice';
import Spinner from '../../UI/Spinner';
import NotAuthorizedPage from '../../pages/notAuthorizedPage';
import OptionsList from '../../components/OptionsList';
import PlayerDetails from './PlayerDetails';
import ResponsivePagination from 'react-responsive-pagination';
import PlayersList from './PlayersList';

const PlayersReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SEARCH_TEXT':
            return { ...state, searchText: action.value, currentPage: 1 };
        case 'SET_CURRENT_PAGE':
            return { ...state, currentPage: action.value };
        case 'SET_PLAYERS':
            return { ...state, players: action.value.players, totalCount: action.value.pagination.totalItemCount };
        case 'SET_SELECTED_PLAYER_ID':
            return { ...state, selectedPlayerId: action.value };
        default:
            return state;
    }
}

const initialInputs = {
    searchText: '',
    currentPage: 1,
    players: [],
    selectedPlayerId: null,
    totalCount: 0,
};

const PlayersPage = () => {
    const [timeoutHandle, setTimeoutHandle] = useState(null);
    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);

    const [state, dispatch] = useReducer(PlayersReducer, initialInputs);
    const { searchText, currentPage, players, selectedPlayerId, totalCount } = state;

    const [getPlayers, { isLoading: loadingPlayers, isFetching: fetchingPlayers }] = useLazyGetPlayersQuery();

    const fetchPlayers = async () => {
        var response = await getPlayers({ pageNumber: currentPage, pageSize: 10, searchQuery: searchText.length > 0 ? searchText : "" });
        if (response.data) {
            dispatch({ type: 'SET_PLAYERS', value: response.data });
        }
    }

    const searchTextChangedHandler = (event) => {
        if (timeoutHandle) {
            clearTimeout(timeoutHandle);
        }
        setTimeoutHandle(setTimeout(() => {
            dispatch({ type: 'SET_SEARCH_TEXT', value: event.target.value });
        }, 1000));
    }

    const selectedPlayerChangedHandler = (playerId) => {
        dispatch({ type: 'SET_SELECTED_PLAYER_ID', value: playerId });
    }

    useEffect(() => {
        fetchPlayers();
    }, [searchText, currentPage]);

    const countPages = totalCount && !loadingPlayers ? Math.ceil(totalCount / 10) : 0;


    if (!user) return <Spinner />;
    else if (!isAdmin) return <NotAuthorizedPage />;
    return (
        <div className="d-flex flex-column">
            {/*master details page*/}
            <div className="row flex-nowrap">
                <div style={{ width: "30%" }}>
                    <input type="text" className="form-control" placeholder="Search" onChange={searchTextChangedHandler} />
                    <PlayersList players={players} count={!loadingPlayers ? totalCount : 0} isLoading={loadingPlayers || fetchingPlayers} selectedPlayerId={selectedPlayerId} onSelectedPlayerChange={selectedPlayerChangedHandler} />

                    <div className=" my-3">
                        <ResponsivePagination
                            current={currentPage}
                            total={countPages}
                            onPageChange={(page) => dispatch({ type: 'SET_CURRENT_PAGE', value: page })}
                            previousLabel="Previous"
                            nextLabel="Next"
                        />
                    </div>
                </div>
                <div className="d-flex flex-column col-9">
                    {selectedPlayerId && <PlayerDetails key={selectedPlayerId} playerId={selectedPlayerId} />}
                </div>
            </div>
        </div>
    );
}


export default PlayersPage;