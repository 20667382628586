import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser, selectIsAdmin } from '../auth/authSlice';
import { useGetIMGAPlayersQuery } from '../api/imgaSlice';
import Spinner from '../../UI/Spinner';
import NotAuthorizedPage from '../../pages/notAuthorizedPage';
import OptionsList from '../../components/OptionsList';
import IMGAPlayerDetails from './IMGAPlayerDetails';

const IMGAPlayersPage = () => {
    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);

    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const { data: players, isLoading } = useGetIMGAPlayersQuery();

    const selectPlayerChangedHandler = (playerId) => {
        const player = players.find(player => player.playerId === playerId);
        setSelectedPlayer(player);
    }

    if (!user) return <Spinner />;
    else if (!isAdmin) return <NotAuthorizedPage />;
    return (
        <div className="d-flex flex-column">
            {/*master details page*/}
            <div className="row flex-nowrap">
                <OptionsList
                    title="Players"
                    options={players?.map((player) => ({
                        label: player.fullName,
                        value: player.playerId,
                    }))
                    }
                    isLoading={isLoading}
                    selectedOption={selectedPlayer?.playerId}
                    setSelectedOption={selectPlayerChangedHandler}
                />
                <div className="d-flex flex-column col-9">
                    {selectedPlayer && <IMGAPlayerDetails player={selectedPlayer} />}
                </div>
            </div>
        </div>
    );
}


export default IMGAPlayersPage;