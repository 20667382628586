import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getAuthToken from '../auth/authManager';

export const dropboxApiSlice = createApi({
    reducerPath: 'dropboxApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/dropbox`,
        mode: 'cors',
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        moveFromOperationsToDropbox: builder.mutation({
            query: ({targetFilePath, dropboxFolder}) => ({
                url: '/move-to-dropbox',
                method: 'POST',
                body: {targetFilePath, dropboxFolder},
            }),
        })
    }),
});

export const {
    useMoveFromOperationsToDropboxMutation
} = dropboxApiSlice;